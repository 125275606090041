.menu-left-custom {
  height: 100%;
  background-color: #fff;
  box-shadow: inset -1px 0px 0px rgba(198, 198, 200, 0.35);
  position: relative;
  transition: all 0.3s;
  .menu-list {
    margin: 0 8px 0 8px;
    overflow: auto;
    height: 100%;
  }
  .icon-back {
    position: absolute;
    right: -11px;
    width: 24px;
    height: 24px;
    background-color: #18bcf3;
    border-radius: 100px;
    top: 19px;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
  }
  .menu-item-title {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #343434;
    width: 253px;
  }
  .menu-item-content {
    width: 280px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 11px 0 11px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1px;
    border-width: 2px;
    border-color: #fff;
    margin: 5px 0;
    font-size: 14px;
    border-style: solid;
  }
  .menu-left-item-active {
    transition: all 0.15s;
    color: #18c9f3;
    background: #fff;
    border-color: #5ac8fa;
  }
}
