.header {
  display: flex;
  align-items: center;
  height: 60px;
  background: #002e5c;
  padding-left: 14px;
  padding-right: 14px;
  /*position: fixed;*/
  top: 0;
  z-index: 1000;
  width: 100%;
  justify-content: space-between;

  .ant-menu {
    background-color: #002e5c;
    width: calc(100% - 182px);
    border-bottom: 0;
    color: #ffffff;
    height: 60px;
    /*font-weight: 600;*/
    user-select: none;
    justify-content: center;
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: initial !important;
    }
    .ant-menu-item {
      height: 60px;
      display: flex;

      .ant-menu-title-content {
        font-size: 21px;
        line-height: 1.3;
        font-weight: 400;
        margin: auto;
      }
    }

    .ant-menu-item-active:after {
      border-bottom: 4px solid #18bcf3 !important;
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:not(.ant-menu-item-active)::after {
    border-bottom: 0 !important;
    color: #ffff;
    opacity: 0.8 !important;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:not(.ant-menu-item-active) {
    color: #ffff;
    opacity: 0.8 !important;
  }
  .avatar-user {
    cursor: pointer;
    user-select: none;
    max-width: 150px;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .name-user {
    color: #fff;
    font-size: 17px;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .logo-container {
    width: 15%;

    .logo {
      height: 32px;
      width: 150px;
      margin: 8px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 0;
      cursor: pointer;
      /*background-image: url('../public/images/logo-header.svg');*/
    }
  }
}
