@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.form-login-success {
  .forgot-password {
    position: absolute;
    right: 0;
    color: #007aff;
    user-select: none;
    top: 4px
  }
}
