.ant-upload.ant-upload-drag {
  border-color: #007aff;
}
.avatar-uploader-article > .ant-upload,
.view-image-upload-article {
  width: 128px !important;
  height: 128px !important;
}

.ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}

.preview-image-article {
  display: flex;
  align-items: center;
  position: relative;
}

.icon-remove-image-article {
  position: absolute;
  top: -12px;
  left: 115px;
  cursor: pointer;
  user-select: none;
}

.ant-form-item .ant-upload {
  background-color: rgba(64, 158, 255, 0.1);
}
