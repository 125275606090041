.table-base {
  position: relative;
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: '';
    width: 0;
  }

  .ant-table-thead > tr > th {
    background: #ffffff;
    padding: 7px 16px;
    text-transform: uppercase;
    font-size: 14px;
    height: 48px;
  }
  .ant-table-tbody > tr > td {
    padding: 12.5px 16px;
  }
  .button-add {
    position: absolute;
    user-select: none;
    top: 58px;
    right: 0;
  }
  .ant-empty-img-default {
    width: 100px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
  }
  .ant-select-selection-search-input {
    height: 42px;
  }
}
