.ant-upload-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewPDFText {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(144, 147, 153, 0.1);
  text-transform: uppercase;
}
