$font-size-base-px: 15px;
$font-size-21: 1rem * (21px / $font-size-base-px);
$line-height-rate-micro: 1.3;
$font-size-17: 1rem * (17px / $font-size-base-px);
$line-height-rate-base: 1.5;
.login {
  background-size: cover;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formLogin {
  width: 567px;
  background: #fdfdfd;
  border-radius: 8px;
  padding: 56px;
  max-height: 636px;
  height: 100%;
  position: relative;

  .description {
    margin: 32px 0;
    .txt-description,
    .ant-btn-link {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      /* or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }

    .ant-btn-link {
      padding: 0;
      margin-left: 5px;
      color: #007aff;
    }
  }
  .block-ip-icon {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 120px;
  }
  .txt-title {
    font-style: normal;
    white-space: pre-wrap;
    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    /* or 28px */

    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }
  .icon-back {
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #0070c9;
  }
  .icon-forgot-success svg {
    width: 83px;
    height: 83px;
  }
}

.spin-login {
  height: 100%;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
}
