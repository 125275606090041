.formAddAccount {
  .title-modal {
    font-size: 21px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    height: 46px;
    display: flex;
    align-items: center;
    margin-top: 22px;
    box-shadow: inset 0px -2px 0px rgba(224, 224, 224, 0.5);
    margin-bottom: 32px;
    span {
      font-size: 15px;
      text-transform: uppercase;
      color: #007aff;
      /*font-weight: 600;*/
    }
  }
  .title svg {
    height: 17px !important;
    width: 17px !important;
  }
}
