.custom-class-modal-confirm {
  .ant-modal-confirm-body > .anticon {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-confirm-btns {
    display: none;
  }
  .ant-modal-confirm-content {
    margin: 0 !important;
    padding-top: 40px;
    font-size: 15px !important;
  }
  .ant-modal-confirm-title {
    font-size: 21px !important;
  }
  .footer-bottom-modal {
    box-shadow: 0px -1px 0px #efeff4;
    padding: 10px 12px;
    text-align: end;
  }
  .ant-modal-confirm-title {
    padding: 16px 24px 0 24px;
  }
}
