.network {
  position: absolute;
  padding: 16px;
  bottom: -100px;
  left: 30px;
  background-color: #242526;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
